import Gallery from './Gallery.js';

import './Area.css';
import Map from './assets/map.svg';
import Barnhouse from './assets/barnhouse.svg';
import Playground from './assets/playground.svg';
import Tinyhouse from './assets/tinyhouse.svg';
import Cafe from './assets/cafe.svg';
import Bathhouse from './assets/bathhouse.svg';
import Parking from './assets/parking.svg';

function Area() {
  return (
    <div className="section" id="area">
      <div className="section-header">
        <h1>Территория</h1>
        <p>Раскинувшийся среди живописной природы глэмпинг Горы Рядом приглашает вас окунуться в атмосферу спокойствия и единения с природой. Прогуляйтесь по тенистым лесным тропинкам, вдохните свежий воздух и насладитесь умиротворяющей тишиной, нарушаемой лишь пением птиц и шелестом листвы</p>
      </div>
      <div className="section-gallery">
        <Gallery />
      </div>
      <div className="section-area-map">
        <img src={Map} alt="" />
      </div>
      <div className="section-area-tags">
        <div>
          <div style={{backgroundColor: "#EEB053"}}>
            <img src={Barnhouse} alt="" />
            <p>Барнхаус</p>
          </div>
          <div style={{backgroundColor: "#EE5D53"}}>
            <img src={Playground} alt="" />
            <p>Детская Площадка</p>
          </div>
        </div>
        <div>
          <div style={{backgroundColor: "#FE833D"}}>
            <img src={Tinyhouse} alt="" />
            <p>Тинихаус</p>
          </div>
          <div style={{backgroundColor: "#AA53EE"}}>
            <img src={Cafe} alt="" />
            <p>Кафе</p>
          </div>
          <div style={{backgroundColor: "#4DCB52"}}>
            <img src={Bathhouse} alt="" />
            <p>Баня</p>
          </div>
          <div style={{backgroundColor: "#000000"}}>
            <img src={Parking} alt="" />
            <p>Парковка</p>
          </div>
        </div>
      </div>
      <div className="section-area-zero"></div>
    </div>
  )
}

export default Area;
