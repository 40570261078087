import './Map.css';

function Map() {
  return (
    <div className="section" id="map">
      <div className="section-header">
        <h1>Карта</h1>
        <p>Удаленный от шума и суеты, глэмпинг ГорыРядом станет вашим личным раем, где вы сможете восстановить силы и обрести гармонию с природой</p>
      </div>
      <div className="map-widget-container">
        {/* <iframe title="ymap" className="map-widget" src="https://yandex.ru/map-widget/v1/?ll=60.369088%2C55.175463&mode=routes&rtext=~55.175300%2C60.369252&rtt=auto&ruri=~&source=serp_navig&z=20.23" width="70%" height="100%" frameborder="0" allowfullscreen="true"></iframe> */}
        <iframe title='ymap' className='map-widget' src="https://yandex.ru/map-widget/v1/?ll=60.364017%2C55.181535&mode=search&oid=240280871433&ol=biz&z=16.14" width="70%" height="100%" frameborder="0" allowfullscreen="true"></iframe>
      </div>
      <div>
        <h2>Как добраться</h2>
        <p>По трасе 75К-273 от Чебаркуля или от с.Яраткулова до д.Крутолапова. Далее по грунтовой дороге 5 км до развилки с указателем "Глэмпинг "ГорыРядом"", затем по указателю 500м до въезда в глэмпинг</p>
      </div>
    </div>
  )
}

export default Map;
