import './Bathouse.css';
import Photo0 from './assets/photo0.jpg';
import Photo1 from './assets/photo1.jpg';
import Photo2 from './assets/photo2.jpg';
import Photo3 from './assets/photo3.jpg';

function Bathouse() {
  return (
    <div className="section">
      <div className="section-header">
        <h1>Баня</h1>
        <p>Баня из кедра с панорамным окном на мысу обещает умиротворенный отдых в объятиях природы. Внутри царит уют, а из окна открывается завораживающий вид на тихую водную гладь. Идеальное место для релакса и гармонии</p>
      </div>
      <div className="bathhouse-container">
        <img src={Photo0} alt="Фото" />
        <img src={Photo1} alt="Фото" />
      </div>
      <div className="bathhouse-container">
        <img src={Photo2} alt="Фото" />
        <img src={Photo3} alt="Фото" />
      </div>
    </div>
  )
}

export default Bathouse;
