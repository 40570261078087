import {useState} from 'react';
import ActivityDetails from './ActivityDetails.js';

import './Activities.css';
//import SwitcherSummer from './assets/SwitcherSummer.svg';
//import SwitcherSummerBG from './assets/SwitcherSummerBG.svg';
//import SwitcherWinter from './assets/SwitcherWinter.svg';
//import SwitcherWinterBG from './assets/SwitcherWinterBG.svg';
//import BG from './assets/bg1.jpg';

import PlaygroundPreview from './assets/PlaygroundPreview.jpg';
import BadmintonPreview from './assets/BadmintonPreview.jpg';

import Skating from './assets/Skating.jpg';
import Hill from './assets/Hill.jpg';
import Skis from './assets/Skis.jpg';
import Snowmobile from './assets/Snowmobile.jpg';
import Tubing from './assets/Tubing.jpg';
import Sledges from './assets/Sledges.jpg';


function WinterOptions({ switchOption, options, selectedActivity }) {
  let value = selectedActivity;

  if (selectedActivity?.description) {
    value = selectedActivity.description;
  } else {
    value = 'none';
  }

  return (
    <div className="activities">
      <button className="activities-summer-btn" onClick={switchOption}>Лето</button>
      <button className="activities-winter-btn activity-selected-btn">Зима</button>
      <div className="activities-options">
        {options.activities.map((activity, index) => (
          <button key={index} onClick={activity.onClick} className={`${value === activity.label ? 'activity-selected-btn' : ''}`}>
            {activity.label}
          </button>
        ))}
      </div>
    </div>
  )
}

function SummerOptions({ switchOption, options, selectedActivity }) {
  let value = selectedActivity;

  if (selectedActivity?.description) {
    value = selectedActivity.description;
  } else {
    value = 'none';
  }

  console.log(options.activities[0][0]);

  return (
    <div className="activities">
      <button className="activities-summer-btn activity-selected-btn">Лето</button>
      <button className="activities-winter-btn" onClick={switchOption}>Зима</button>
      <div className="activities-options">
        {options.activities.map((activity, index) => (
          <button key={index} onClick={activity.onClick} className={`${value === activity.label ? 'activity-selected-btn' : ''}`}>
            {activity.label}
          </button>
        ))}
      </div>
    </div>
  )
}

function Activities() {
  const [isSummerChosen, setIsSummerChosen] = useState(true);
  const [selectedActivity, setSelectedActivity] = useState(null);

  const changeOption = () => {
    setIsSummerChosen(!isSummerChosen);
    setSelectedActivity(null);
  };

  const showDetails = (image, description) => {
    setSelectedActivity({ image, description });
  };

  const summerOptions = {
    activities: [
      { label: 'Детская Площадка', onClick: () => showDetails(PlaygroundPreview, 'Детская Площадка') },
      { label: 'Бадминтон', onClick: () => showDetails(BadmintonPreview, 'Бадминтон') },
      // Добавьте другие активности лета
    ],
  };

  const winterOptions = {
    activities: [
      { label: 'Каток', onClick: () => showDetails(Skating, 'Каток') },
      { label: 'Горка', onClick: () => showDetails(Hill, 'Горка') },
      { label: 'Лыжи', onClick: () => showDetails(Skis, 'Лыжи') },
      { label: 'Снегоход', onClick: () => showDetails(Snowmobile, 'Снегоход') },
      { label: 'Тюбинги', onClick: () => showDetails(Tubing, 'Тюбинги') },
      { label: 'Санки', onClick: () => showDetails(Sledges, 'Санки') },
      // Добавьте активности зимы
    ],
  };


  return (
    <div className="section" id="activities">
      <div className="section-header">
        <h1>Активности</h1>
        <p>Откройте для себя множество увлекательных занятий, которые подарят вам незабываемые впечатления. Исследуйте лесные тропы во время пеших прогулок или прокатитесь на велосипеде по живописным окрестностям.</p>
      </div>
      {isSummerChosen ? (
        <SummerOptions switchOption={() => changeOption()} options={summerOptions} selectedActivity={selectedActivity} />
      ) : (
        <WinterOptions switchOption={() => changeOption()} options={winterOptions} selectedActivity={selectedActivity} />
      )}
      <div className="activities-components">
        {selectedActivity && (
          <ActivityDetails image={selectedActivity.image} description={selectedActivity.description} />
        )}
      </div>
    </div>
  )
}

export default Activities;
