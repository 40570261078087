function ActivityDetails({ image, description }) {
  return (
    <div>
      <div>
        <img src={image} alt="Выбранное занятие" className="activity-selected-preview" />
      </div>
      <p>{description}</p>
    </div>
  );
}

export default ActivityDetails;
