import AnchorLink from 'react-anchor-link-smooth-scroll';
import './Pricing.css';

function Pricing() {
  return (
    <div className="section" id="pricing">
      <div className="section-header">
        <h1>Стоимость</h1>
        <p>Мы предлагаем различные варианты проживания, чтобы каждый мог найти идеальный баланс между комфортом и бюджетом. Выберите уютный домик: аутентичный барнхаус или компактный тинихаус, и позвольте нам позаботиться об остальном</p>
      </div>
      <div className="pricing-category">
        {/*<h2>Будние Дни</h2>*/}
        <div className="pricing-option">
          <div>
            <p className="pricing-option-date">Барнхаус</p>
            <p className="pricing-option-price">15 000₽/сутки</p>
            <div className="pricing-details">
              <p>До 4 взрослых + 1 ребёнок на раскладном диване</p>
            </div>
          </div>
          <AnchorLink className="" href="#contacts">Выбрать</AnchorLink>
        </div>
        <div className="pricing-option">
          <div>
            <p className="pricing-option-date">Тинихаус</p>
            <p className="pricing-option-price">10 000₽/сутки</p>
            <div className="pricing-details">
              <p>До 2 взрослых + 1 ребенок на выкатном спальном месте</p>
            </div>
          </div>
          <AnchorLink className="" href="#contacts">Выбрать</AnchorLink>
        </div>
        {/*<h2>Выходные Дни</h2>
        <div className="pricing-option">
          <div>
            <p className="pricing-option-date">От 2 ночей</p>
            <p className="pricing-option-price">18 000₽/сутки</p>
            <div>
              <p>С 1 человека</p>
            </div>
          </div>
          <button>Выбрать</button>
        </div>
        <div className="pricing-option">
          <div>
            <p className="pricing-option-date">От 4 ночей</p>
            <p className="pricing-option-price">15 500₽/сутки</p>
            <div>
              <p>С 1 человека</p>
            </div>
          </div>
          <button>Выбрать</button>
        </div>*/}
      </div>
      <div className="section-pricing-zero"></div>
    </div>
  )
}

export default Pricing;
