import './Contacts.css';

function Contacts() {
  return (
    <div className="section" id="contacts">
      <div className="section-header">
        <h1>Контакты</h1>
        <p>Мы предлагаем различные варианты проживания, чтобы каждый мог найти идеальный баланс между комфортом и бюджетом. Выберите уютный домик: аутентичный барнхаус или компактный тинихаус, и позвольте нам позаботиться об остальном</p>
      </div>
      <div className="pricing-category">
        {/*<h2>Будние Дни</h2>*/}
        <div className="pricing-option phone">
          <div>
            <p className="pricing-option-price">+7 (919) 111-12-42</p>
          </div>
          <a href="tel:+79191111242">Позвонить</a>
        </div>
        <div className="pricing-option phone">
          <div>
            <p className="pricing-option-price">+7 (908) 081-21-56</p>
          </div>
          <a href="tel:+79080812156">Позвонить</a>
        </div>
        {/*<h2>Выходные Дни</h2>
        <div className="pricing-option">
          <div>
            <p className="pricing-option-date">От 2 ночей</p>
            <p className="pricing-option-price">18 000₽/сутки</p>
            <div>
              <p>С 1 человека</p>
            </div>
          </div>
          <button>Выбрать</button>
        </div>
        <div className="pricing-option">
          <div>
            <p className="pricing-option-date">От 4 ночей</p>
            <p className="pricing-option-price">15 500₽/сутки</p>
            <div>
              <p>С 1 человека</p>
            </div>
          </div>
          <button>Выбрать</button>
        </div>*/}
      </div>
      <div className="section-pricing-zero"></div>
    </div>
  )
}

export default Contacts;
