import AnchorLink from 'react-anchor-link-smooth-scroll';
import './Welcome.css';
//import bg from './assets/bg.jpg';

function Welcome() {
  return (
    <div className="welcome-section">
      <div className="welcome-container">
          <div>
            <h1>Открой глаза на природу, закрой глаза на заботы</h1>
            <p>Вы откроете перед собой изумительное озеро и захватывающие виды на окружающие горы.
            Погрузитесь в гармонию с природой и наслаждайтесь комфортом в нашем уникальном месте.</p>
            <AnchorLink href="#area">Узнать Подробнее</AnchorLink>
          </div>
      </div>
    </div>
  )
}

export default Welcome;
